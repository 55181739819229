
import { defineComponent } from "vue";
import { modalController, IonButtons } from "@ionic/vue";

export default defineComponent({
  name: "HomeScreen",
  components: {
    IonButtons,
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
  },
});
