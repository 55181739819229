
declare var google: any;

import { defineComponent } from "vue";
import { isPlatform, IonCol, IonRow, modalController } from "@ionic/vue";
import Design from "./Design.vue";
import Cart from "./Cart.vue";

enum views {
  DESIGN = "design",
  CHECKOUT = "checkout",
  ENGRAVED = "engraved",
  SHIPPED = "shipped",
  DELIVERED = "delivered",
}

enum selectClass {
  SELECTED = "selected",
  NOT_SELECTED = "",
}

enum iconSet {
  DOWN = "caret-circle-down",
  UP = "circle",
}

let autocomplete: any;
// let address1Field;
// let address2Field;
// let postalField;

export default defineComponent({
  name: "HomeScreen",
  components: {
    Design,
    IonCol,
    IonRow,
  },
  data: function () {
    return {
      view: "design",
      sirname: "Sample",
      shipToName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postcode: "",
      phone: "",
      googleKey: "AIzaSyCR-ajN4rkwMKMO2cFdoL1obSmhwgeOFPM",
      cartTotal: 0,
      mounted: false,
    };
  },
  computed: {
    isHybrid() {
      return isPlatform("hybrid");
    },
  },
  methods: {
    changeClass: function (view: views): selectClass {
      return this.view === view ? selectClass.SELECTED : selectClass.NOT_SELECTED;
    },
    changeIcon: function (view: views): iconSet {
      return this.view === view ? iconSet.DOWN : iconSet.UP;
    },
    // changeScreen: function (view: views): void {
    //   this.view = view;
    //   // this.$router.push({ path: '/' + view });
    //   if (view === "design") {
    //     this.mountInput();
    //   }
    // },
    openCart: async function (): Promise<void> {
      const modal = await modalController.create({
        component: Cart,
      });
      return modal.present();
    },
    mountInput: function (): void {
      if (!this.mounted) {
        this.mounted = true;
        console.log("ionViewDidEnter");
        // window.onload = () => {
        const address1Field = document.querySelector("#ship-address input");
        console.log("address1Field", address1Field);
        autocomplete = new google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ["us"] },
          fields: ["address_components"],
          types: ["address"],
        });
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          for (const component of place.address_components) {
            const componentType = component.types[0];
            // console.log("componentType", componentType);
            // console.log("component", component);

            switch (componentType) {
              case "street_number": {
                this.address1 = component.long_name;
                break;
              }

              case "route": {
                this.address1 += " " + component.short_name;
                break;
              }

              case "locality": {
                this.city += component.long_name;
                break;
              }

              case "administrative_area_level_1": {
                this.state = component.long_name;
                break;
              }

              case "postal_code": {
                this.postcode = `${component.long_name}${this.postcode}`;
                break;
              }

              case "postal_code_suffix": {
                this.postcode = `${this.postcode}-${component.long_name}`;
                break;
              }
            }
          }
          if (this.$refs.address2) (this.$refs.address2 as any).$el.setFocus();
        });
      }
    },
  },
});
