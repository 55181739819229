import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import {
  IonicVue,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonButton,
  IonInput,
  IonRange,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";
import { FontAwesomeIcon } from "@/plugins/font-awesome";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

// const app = createApp(App).use(IonicVue).use(router);

// router.isReady().then(() => {
//   app.mount("#app");
// });

const app = createApp(App).use(IonicVue).use(router);

router.isReady().then(() => {
  app
  // eslint-disable-next-line vue/multi-word-component-names
    .component("fa", FontAwesomeIcon)
    .component("ion-page", IonPage)
    .component("ion-content", IonContent)
    .component("ion-header", IonHeader)
    .component("ion-toolbar", IonToolbar)
    .component("ion-title", IonTitle)
    .component("ion-item", IonItem)
    .component("ion-label", IonLabel)
    .component("ion-button", IonButton)
    .component("ion-input", IonInput)
    .component("ion-range", IonRange)
    .component("ion-segment", IonSegment)
    .component("ion-segment-button", IonSegmentButton)
    .mount("#app");
});
