<template>
  <div>
    <div id="preview">
      <div id="designer" :class="classLevel">
        <div>{{ firstLetter }}</div>
        <div>{{ sirname }}</div>
      </div>
    </div>

    <!--button @click="process()">Process</button>
    <br />
    <div id="reveal"></div-->
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import domtoimage from "dom-to-image";

export default defineComponent({
  name: "DesignScreen",
  props: {
    sirname: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      // sirname: "Wax",
      classLevel: "",
    };
  },
  computed: {
    firstLetter() {
      return this.sirname.charAt(0);
    },
  },
  // methods: {
  //   process() {
  //     console.log("process");
  //     this.classLevel = "unzoom";

  //     const node = document.getElementById("designer");
  //     domtoimage
  //       .toPng(node, { quality: 1.0, dpi: 768 })
  //       .then((dataUrl) => {
  //         const img = new Image();
  //         img.src = dataUrl;
  //         document.getElementById("reveal").appendChild(img);
  //         this.classLevel = "";
  //       })
  //       .catch(function (error) {
  //         console.error("oops, something went wrong!", error);
  //       });
  //   },
  // },
});
</script>

<style scoped>
@font-face {
  font-family: "Castelar";
  src: url(/public/assets/castelar.ttf);
}
#preview {
  /* cursor: pointer; */
  cursor: default;
  border: 5px #f8f6ef solid;
  color: #5d3100;
  fill: #5d3100;
  border-radius: 10px;
  background: #15191a url(/public/assets/template.png) top left no-repeat;
  margin: 0px auto;
  position: relative;
  background-size: contain;
  max-width: 600px;
  /* min-width: 300px; */
  aspect-ratio: 16/7.27;
  padding-right: 2vw;
  display: grid;
  place-items: center;
}
#designer {
  display: inline-block;
  font-family: "Castelar", serif;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 5px;
}
#designer.unzoom {
  zoom: 100%;
}
#designer > div:nth-child(1) {
  text-align: center;
  font-size: 19vw;
  line-height: 15vw;
  border-bottom: 0.5vw #5d3100 solid;
  margin-bottom: 3px;
}
#designer > div:nth-child(2) {
  display: inline-block;
  border-top: 1vw #5d3100 solid;
  text-align: center;
  font-size: 9vw;
  line-height: 10vw;
  text-transform: uppercase;
  padding-top: 1%;
}
@media screen and (min-width: 600px) {
  #designer > div:nth-child(1) {
    border-bottom-width: 2.98438px;
    font-size: 113.81px;
    line-height: 89.85px;
  }
  #designer > div:nth-child(2) {
    border-top-width: 5.98438px;
    font-size: 53.91px;
    line-height: 59.9px;
  }
}
#reveal {
  display: inline-block;
  border: 1px red solid;
}
</style>
