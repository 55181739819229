
import { defineComponent } from "vue";
import { isPlatform } from "@ionic/vue";

export default defineComponent({
  name: "LoginScreen",
  components: {
  },
  data: function () {
    return {
    };
  },
  computed: {
    isHybrid() {
      return isPlatform("hybrid");
    },
  },
});
